import * as React from "react"
import { Link } from "gatsby"

const Header = ({ homePage }) => {
  if (typeof window !== "undefined") {
    if (window.location.pathname.startsWith("/blog/")) var linkActive = "active"
  }
  return (
    <header className={homePage ? "home-page" : ""}>
      <Link to="/" activeClassName="active">
        HOME<div className="spacer"></div>
      </Link>
      <Link to="/#about">
        Over mij<div className="spacer"></div>
      </Link>
      <Link to="/#contact">
        CONTACT<div className="spacer"></div>
      </Link>
      <Link to="/blog" activeClassName="active" className={linkActive}>
        blog<div className="spacer"></div>
      </Link>
    </header>
  )
}

export default Header
